import React from 'react';
import style from './Footer.module.css';
import {Container, Row} from "react-bootstrap";
import kart from '../../assets/images/kart.jpg'
import icon from './../../assets/images/main.png'
import iprbooks from './../../assets/images/ipr.svg'
import kstud from './../../assets/images/kstud.png'
import urait from './../../assets/images/urait.jpg'
import neb from './../../assets/images/neb.png'
import prof from './../../assets/images/profobrazovanie.png'

const Footer = () => {
   
    return (
        <div className={style.cont}>
            <Container>
                <div className={style.top}>
                    <Row>
                        <div className="col-md-4 col-12">
                            <div className={style.footerTitle}><img src={icon} alt=""/> Library
                            </div>
                            <div className={style.footerSubtitle}>Открытая база научных работ ГГНТУ</div>


                        </div>
                        <div className="col-md-5 col-12">
                            <div className={style.footerTitle}>Контакты
                            </div>
                            <div className={style.footerSubtitle}><i className="fas fa-envelope" /> ebs_ggntu@mail.ru</div>
                            <div className={style.footerSubtitle}><i className="fas fa-phone" /> 8963-703-9996</div>
                        </div>
                        <div className="col-md-3 d-md-block d-none col-0">
                            <img className={style.kart} src={kart} alt="kart"/>
                        </div>
                    </Row>
                    <div className="row">
                        <div className='mt-5 col-12'>
                            <a href="https://profspo.ru/" target='_blank'><img className={style.fotImg} src={prof} alt=""/></a>
                            <a style={{display: 'inline-block', backgroundColor: "white", borderRadius: 5, marginRight: 15}} href="https://www.iprbookshop.ru/" target='_blank'><img className={style.fotImg} src={iprbooks} alt=""/></a>
                            {/*<a href="https://www.studentlibrary.ru/" target='_blank'><img className={style.fotImg} src={kstud} alt=""/></a>*/}
                            {/*<a href="https://urait.ru/" target='_blank'><img className={style.fotImg} src={urait} alt=""/></a>*/}
                            <a href="https://rusneb.ru/" target='_blank'><img className={style.fotImg} src={neb} alt=""/></a>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className={style.bottom}>
                    <Row>
                        <div className="col-12">
                            <div className={style.desc}>
                                Содержание © 2022 ГГНТУ
                            </div>
                        </div>

                    </Row>
                </div>
            </Container>
        </div>
    )
}
export default Footer;